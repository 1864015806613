import BaseObject from 'ol/Object'
import Feature from 'ol/Feature'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'

import { app, glo } from './globo'
import { fdf, mylink } from './libra'
import { geojformat } from './carte'

const ObjType = 'o'

// vol.id,
// vol.name AS n,
// date_part('epoch'::text, vol.touch)::integer AS ts,
// vol.zore AS zi,                        * unused
// vol."full" AS f,                       * unused
// st_asgeojson(st_asewkt(vol.mp)) AS j,
// st_asewkt(vol.mp) AS e,                * unused
// vol.mp AS pz                           * unused (only on server)
// // added with airspa
// c        * class
// t        * type
// d        * descr
// ac       * activcode
// ad       * activdesc
// um       * upperM
// us       * upper
// lm       * lowerM
// ls       * lower
// sk       * stk stroke
// swi      * swid stroke-width
// sop      * sopa stroke-opacity
// fi       * fil fill
// fop      * fopa fill-opacity

// local

// function volDrawAll() { $.each(app.getObjs(ObjType), function(o, obj) { obj.draw(); }); }

function getVol (oid) {
  const co = app.getObj(ObjType, oid)
  if (co) { volUpd(co); return }
  try {
    const eb = app.getEB()
    const sid = app.getv('sid')
    eb.send('go', { id: oid, ot: ObjType }, { sid: '' + sid },
      function (err, r) {
        if (err) { return (null) } else { volIns(r.body[0]) }
      }
    )
  } catch (err) { console.error('GetVol err: ' + err) }
}

function volIns (ojs) {
  const obj = new Volume(ojs)
  const id = obj.id
  if (app.getObjs(ObjType)[id]) app.getObjs(ObjType)[id].remove()
  app.getObjs(ObjType)[id] = obj
  obj.draw()
}

function volUpd (ojs) {
  let obj = app.getObj(ObjType, ojs.id)
  if (obj) {
    obj.update(ojs)
  } else {
    obj = new Volume(ojs)
    app.getObjs(ObjType)[obj.id] = obj
  }
  obj.draw()
}

function volLst (list) {
  if (list) list.forEach((ojs) => volUpd(ojs))
}

function volDel (obj) {
  const ro = app.getObj(ObjType, obj.id)
  if (ro) ro.remove()
}

function volStyle (f, reso) {
  if (!f || typeof f === 'undefined') return []
  const oid = f.getId()
  const vol = app.getObj(ObjType, oid)
  return vol._currentStyle()
}

const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100
  }

  return `rgba(${r},${g},${b},${opacity})`
}

// function randCol () { return 30 + Math.round(Math.random() * 200) }

class Volume extends BaseObject {
  constructor (ojs) {
    super()
    for (const key in ojs) { this[key] = ojs[key] }
    this.ot = ObjType
  }

  update (ojs) {
    if (!ojs) {
      this.refresh()
    } else {
      let changed = false
      for (const key in ojs) {
        if (this[key] !== ojs[key]) {
          changed = true
          this[key] = ojs[key]
        }
      }
      if (changed) this.refresh()
    }
  }

  matchAlti (u, l) {
    return (this.lm < u && this.um > l)
  }

  draw () {
    if (app.volMatchAlti([this.id]) < 1) { return }
    const geom = geojformat.readGeometry(this.j)
    geom.applyTransform(app.tr2map)
    const fea = new Feature({
      geometry: geom,
      ot: ObjType
    })
    fea.setId(this.id)
    app.getSrcs(ObjType).addFeature(fea)
    //        fea.changed();
  }

  undraw () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) app.getSrcs(ObjType).removeFeature(fea)
  }

  refresh () {
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.changed()
  }

  remove () {
    this.undraw()
    app.getObjs(ObjType)[this.id] = null
    delete app.getObjs(ObjType)[this.id]
  }

  _oneLiner () {
    const cnt = 'AirSpace ' + this.c + '/' + this.t + ' ' + this.n + ' (' + this.ls + ' - ' + this.us + ')'
    return cnt
  }

  _norStyle () {
    const norSty = new Style({
      stroke: new Stroke({ color: convertHexToRGBA(this.sk, this.sop), width: this.swi + 1 })
    })
    return [norSty]
  }

  _selStyle () {
    const selSty = new Style({
      stroke: new Stroke({ color: convertHexToRGBA(this.sk, this.sop) + 0.1, width: this.swi + 2, lineDash: [5, 3] }),
      fill: new Fill({ color: convertHexToRGBA(this.fi, this.fop + 0.1) })
    })
    return [selSty]
  }

  _currentStyle () {
    return (this.opSel === true) ? this._selStyle() : this._norStyle()
  }

  _emphase (son) {
    this.opSel = son
    // console.info('EMPHASE ' + son + ' ' + this.id + ' glv(' + glo.lastVol + ')')
    const sty = (son === true) ? this._selStyle() : this._norStyle()
    const fea = app.getSrcs(ObjType).getFeatureById(this.id)
    if (fea) fea.setStyle(sty)
    if (son === true) glo.lastVol = this.id
  }

  _volDisplay () {
    app.selectObj(ObjType, this.id)
    const dmd = new Date(this.ts * 1000)
    const odmd = fdf(dmd)
    const dad = new Date(this.sa * 1000)
    let odad = fdf(dad)
    const dod = new Date(this.so * 1000)
    let odod = fdf(dod)
    if (this.sa < 1) { odad = 'UNL' }
    if (this.so > 6090689400) { odod = 'UNL' } // 6090689407 = 2163-01-03 03:10:07+01
    let txt = '<fieldset><legend><span id="b_cl"><span class="pc-cl">' + glo.symb.close.c + '</span>&nbsp;AirSpace (#' + this.id + ')</span></legend><table style="table-layout:fixed">' +
     '<tr><td>Nom</td><td>' + this.n + '</td></tr>' +
     '<tr><td>GUId</td><td>' + this.gi + '</td></tr>' +
     '<tr><td>Classe/Type</td><td><span style="font-weight:bold">' + this.c + ' / ' + this.t + '</span></td></tr>' +
     '<tr><td>Plafond</td><td><span style="font-weight:bold">' + this.us + ' &nbsp; (' + this.um + ' m)</span></td></tr>' +
     '<tr><td>Plancher</td><td><span style="font-weight:bold">' + this.ls + ' &nbsp; (' + this.lm + ' m)</span></td></tr>' +
    // '<tr><td colspan="2"><span style="white-space:pre-wrap;word-wrap:break-word">' + this.d + '</span></td></tr>' +
    '<tr style="font-size:smaller"><td>D&eacute;but - Fin</td><td>' + odad + ' - ' + odod + '</td></tr>' +
    '<tr><td>Activation</td><td><span style="font-weight:bold">' + this.ac + '</span></td></tr>' +
    '<tr><td colspan="2"><span style="font-weight:bold">' + ((this.ad) ? this.ad : '') + '</span></td></tr>'
    if (this.url != null) { txt += '<tr><td>URL</td><td><a href="' + this.url + '" target="_blank">' + this.url + '</td></tr>' }
    txt += '<tr style="font-size:smaller"><td>M&agrave;J</td><td>' + odmd + '</td></tr>'
    txt += '</table>'
    txt += '<pre>' + ((this.d && this.d.length > 0) ? mylink(this.d) : '') + '</pre>'
    txt += '</fieldset>'
    return (txt)
  }

  getDisplay () { return (this._volDisplay()) }
  getOneLiner () {
    if (glo.lastVol !== -1) {
      const ls = app.getObj(ObjType, glo.lastVol)
      if (ls) { ls._emphase(false) } else { console.error('NO OBJECT for last Volume ' + glo.lastVol) }
      glo.lastVol = -1
    }
    this._emphase(true)
    return (this._oneLiner())
  }
}

export { Volume, volStyle, getVol, volLst, volIns, volUpd, volDel }
